.custom-swiper-navigation {
  position: relative;
  display: flex;

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 30px;
  }

  .swiper-next,
  .swiper-prev {
    align-items: center;
    color: $primary;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    top: 50%;
    width: 18px;
    z-index: 10;

    &::after {
      font-family: swiper-icons;
      font-size: 1.5rem;
      font-variant: normal;
      letter-spacing: 0;
      line-height: 1;
      text-transform: none !important;
      text-transform: none;
    }
  }

  .swiper-next {
    margin-left: 1.5rem;
    &::after {
      content: "next";
    }
  }
  .swiper-prev {
    &::after {
      content: "prev";
    }
  }
}
