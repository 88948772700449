.fn-gantt .bottom,
.fn-gantt .leftPanel .name,
.fn-gantt .leftPanel .desc,
.fn-gantt .rightPanel .month,
.fn-gantt .rightPanel .year,
.fn-gantt .leftPanel .spacer {
  background-color: transparent;
}

.fn-gantt .bar .fn-label {
  text-shadow: none;
}

.fn-gantt .navigate {
  border: none;
  padding: 10px 0 0 350px;
}

.fn-gantt .leftPanel {
  width: 350px;
}

.fn-gantt .leftPanel .name {
  width: 45%;
}

.fn-gantt .leftPanel .name.fn-wide {
  width: 100%;
}

.fn-gantt .leftPanel .name .fn-label {
  width: 100%;
  font-size: 12px;
}

.fn-gantt .leftPanel .desc {
  width: 55%;
}

.fn-gantt .leftPanel .desc .fn-label {
  width: 100%;
  font-size: 9px;
}

/* mreža */
.fn-gantt .leftPanel .fn-label {
  margin-left: 0 !important;
  padding-left: 5px;
}
.fn-gantt .dataPanel:before {
  content: "";
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/assets/image/box3.png) repeat scroll left top;
}

.fn-gantt .rightPanel .month,
.fn-gantt .rightPanel .year {
  .fn-label {
    width: 100%;
  }
}

.fn-gantt .row.header {
  position: relative;
  z-index: 9;
}
.fn-gantt .row.header:first-of-type {
  border-bottom: thin solid #ddd;
  border-left: none;
  border-right: none;
  // height: 0;
}

.fn-gantt .nav-link.nav-now {
  background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 .25a10.03 10.03 0 0 1 9.22 6.1 9.8 9.8 0 0 1 .78 3.9 10.03 10.03 0 0 1-6.1 9.22 9.8 9.8 0 0 1-3.9.78 10.03 10.03 0 0 1-9.22-6.1 9.8 9.8 0 0 1-.78-3.9 10.03 10.03 0 0 1 6.1-9.22A9.8 9.8 0 0 1 10 .25zm0 18.77a8.83 8.83 0 0 0 8.06-12.18A8.91 8.91 0 0 0 13.4 2.2 8.44 8.44 0 0 0 10 1.5a8.91 8.91 0 0 0-8.06 5.34 8.44 8.44 0 0 0-.69 3.41c0 1.21.23 2.35.7 3.4A8.83 8.83 0 0 0 10 19.02zm.63-9.02l2.94 2.95a.6.6 0 0 1 .18.43.6.6 0 0 1-.18.44.57.57 0 0 1-.44.2.57.57 0 0 1-.43-.2l-3.11-3.08-.01-.03-.01-.01a.48.48 0 0 1-.15-.2.65.65 0 0 1-.04-.25V4a.6.6 0 0 1 .18-.44.6.6 0 0 1 .44-.19.6.6 0 0 1 .44.2.6.6 0 0 1 .19.43v6z' fill='%2300AADA' fill-rule='nonzero'/%3E%3C/svg%3E%0A")
    no-repeat scroll center/auto 100% !important;
  background-position: center !important;
  border: none;
  box-shadow: none;
  border-radius: 0;
  width: 21px;
}

.fn-gantt .navigate {
  padding-bottom: 0;
}

.fn-gantt .navigate .nav-slider-button {
  background: none;
  margin-top: -10px;
}
.fn-gantt .navigate .nav-slider-button:before {
  background-color: $primary;
  border-radius: 100%;
  left: 0;
  top: 50%;
  margin-top: -10px;
  height: 18px;
  width: 18px;
}

.fn-gantt .navigate .nav-slider {
  width: 100%;
}

.fn-gantt .navigate .nav-slider-content {
  margin: 0;
  margin-left: 10px;
  position: relative;
  height: 40px;
  /* width: 747px; */
  width: calc(100% - 50px);
}

.fn-gantt .navigate .nav-slider-left,
.fn-gantt .navigate .nav-slider-right {
  padding-top: 7px;
}

.fn-gantt .navigate .nav-slider-bar {
  background-color: transparent;
  box-shadow: none;
  height: 40px;
  margin: 0;
  position: relative;
  /* width: 805px; */
  width: 100%;
}

.fn-gantt .navigate .nav-slider-bar:before {
  background-color: #dbdbdb;
  content: "";
  height: 6px;
  left: 0;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.fn-gantt .bar.locked {
  cursor: not-allowed;
  opacity: 0.5;
}

.fn-gantt .bar {
  margin: 0;
  cursor: pointer;
}

#progressRowWrapp {
  float: none;
  position: relative;
  display: block;
}

.fn-gantt-hint {
  background-color: #e6e7e8;
  border: 2px solid $primary;
  font-size: 12px;
  padding: 5px 10px;
}

.fn-gantt .row.highlighted .fn-label {
  color: $primary;
  border: thin solid $primary;
  height: 23px;
}

.fn-gantt .row.highlighted-start .fn-label {
  background-color: rgba(107, 207, 235, 0.23);
}

/* Hide control buttons */
.fn-gantt .navigate .nav-zoomIn,
.fn-gantt .navigate .nav-zoomOut,
.fn-gantt .navigate .nav-page-back,
.fn-gantt .navigate .nav-page-next,
.fn-gantt .navigate .page-number,
.fn-gantt .navigate .nav-prev-week,
.fn-gantt .navigate .nav-next-week,
.fn-gantt .navigate .nav-prev-day,
.fn-gantt .navigate .nav-next-day {
  display: none;
}

/* special days */
.fn-gantt .day.stretched {
  border-color: rgba(229, 228, 228, 0.58);
  border-left: thin solid rgba(229, 228, 228, 0.58);
}

.fn-gantt .day.stretched .fn-label {
  border-bottom: thin solid #ddd;
  line-height: 23px;
}

.fn-gantt .sa,
.fn-gantt .sn,
.fn-gantt .holiday {
  /* color: #FFF; */
  background-color: #d0cbcb;
}

.fn-gantt .day.stretched {
  border-color: rgba(255, 255, 255, 0.58);
  border-left: thin solid rgba(255, 255, 255, 0.58);
}

.fn-gantt .bar.faker {
  display: none;
}

.fn-gantt {
  .row.day {
    &.sa, &.sn, &.holiday {
      height: 200vh;
    }
  }
}

/* .fn-gantt .day.today,
        .fn-gantt .day.sa,
        .fn-gantt .day.sn,
        .fn-gantt .day.holiday {
        } */
/* custom colours */
#gantt .fn-gantt .znamka-vw {
  background-color: #2274ac;
}

#gantt .fn-gantt .znamka-vw.fn-label,
#gantt .fn-gantt .znamka-vw .fn-label {
  color: #fff !important;
}

#gantt .fn-gantt .znamka-audi {
  background-color: #df163a;
}

#gantt .fn-gantt .znamka-audi.fn-label,
#gantt .fn-gantt .znamka-audi .fn-label {
  color: #fff !important;
}

#gantt .fn-gantt .znamka-seat {
  background-color: #a1040d;
}

#gantt .fn-gantt .znamka-seat.fn-label,
#gantt .fn-gantt .znamka-seat .fn-label {
  color: #fff !important;
}

#gantt .fn-gantt .znamka-koda {
  background-color: #4aa82e;
}

#gantt .fn-gantt .znamka-koda.fn-label,
#gantt .fn-gantt .znamka-koda .fn-label {
  color: #fff !important;
}
