.mat-dialog-container {
  // padding: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .btn-print-pdf {
    margin-bottom: 0 !important;
    cursor: pointer;
    padding: 0.625rem 1.5rem;
    margin: 0;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    text-align: center;
    height: auto;
    line-height: 1;
    padding-left: 2.5rem;

    color: $primary;
    background-color: #e6e7e8;
    background-position: left 15px center !important;
    background-size: auto 16px !important;
    padding-left: 40px !important;
  }

  form {
    mat-form-field {
      width: 100%;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 8px !important;
      border: thin solid $primary;
    }
  }

  width: 100vw !important;
  @media screen and (min-width: 767px) {
    width: 70vw !important;
  }
  @media screen and (min-width: 1200px) {
    width: 40vw !important;
  }

  .modal-close-btn {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowNjgwMTE3NDA3MjA2ODExODIyQUUzNjkxMjVFMzYxOSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDNjQ5OTM1RjI0MEQxMUU1OTM2NkZDRTgyODE5MzhBMSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDNjQ5OTM1RTI0MEQxMUU1OTM2NkZDRTgyODE5MzhBMSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUFGOUQ2QjAxODIwNjgxMTgwODNBNkUyOTE1Rjc1N0MiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDY4MDExNzQwNzIwNjgxMTgyMkFFMzY5MTI1RTM2MTkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5kpHePAAAAlElEQVR42pySgQ2AIAwEhQVkBDaQURyBERiFkRzBERzBDWoJbcSmYMInn2jl+OajAYBlRrZ5Dmg3OOvJVSURvUPViXY0ax3QN9mXmRW3buhDJAearWTXJhZHeMXJnMSKfF6uJGEV0kAJq1APlOtdWmF/EPTaHkGxU9gHDIMiVJg/plERAk5y1dyBWjjzu5n9yR8BBgDMeuBaPT2+kQAAAABJRU5ErkJggg==")
      no-repeat center;
    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    right: 15px;
    top: 50%;
    margin-top: -7px;
    cursor: pointer;
  }

  .mat-dialog-title {
    background-color: $primary !important;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    border-bottom-color: transparent;
    position: relative;
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 -24px;
    h3 {
      font-size: 22px;
      font-weight: normal;
      display: inline-block;
      margin: 0;
      padding: 0;
      color: #fff !important;
    }
  }
  .mat-dialog-content {
    padding-bottom: 24px;
    font-size: 0.875rem;
    line-height: 18px;
    padding: 18px 15px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 65vh !important;

    &.gallery-lightbox-content {
      max-height: 85vh !important;
    }

    form {
      label {
        font-size: 12px;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
      height: 42px;
    }
    .mat-form-field {
      margin-bottom: 1rem;
    }
    .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label,
    .mat-form-field-appearance-fill.mat-form-field-can-float
      .mat-input-server:focus
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      display: none !important;
    }
    .mat-form-field-label-wrapper {
      height: 100%;
      padding: 0;
      top: 0;

      .mat-form-field-label {
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }
    }
    .mat-form-field-appearance-fill {
      .mat-form-field-infix {
        padding: 0;
        border-top: none;
      }
      .mat-form-field-flex {
        padding: 4px !important;
        padding-left: 1rem !important;
        height: 42px;
        align-items: center;
      }
      .mat-select-arrow-wrapper {
        transform: none !important;
      }

      .mat-select-value {
        padding-bottom: 0;
        padding-top: 4px;
      }
    }

    mat-select-trigger {
      height: 42px !important;
    }
  }
  .mat-dialog-actions {
    text-align: right;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    padding: 10px 15px;
    margin: 0 -24px;
    min-height: auto;

    button {
      margin-bottom: 0 !important;
      cursor: pointer;
      padding: 0.625rem 1.5rem;
      margin: 0;

      border: none;
      background-color: $primary;
      color: #f3f3f3;

      font-size: 0.875rem;
      display: inline-flex;
      align-items: center;
      text-align: center;
      height: auto;
      line-height: 1;

      &.btn-red {
        background-color: #cc3f44;
        border: thin solid #cc3f44 !important;
      }
    }
  }
}
