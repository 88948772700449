$black: #363636;
$secondary-black: #2b2a2d;
$primary: #00aada;
$primary-background: #f6f6f6;
$white: #ffffff;
$light-gray: #9b9b9b;
$gray: #ccc;
$gray-border: #eaeaea;
$red: #d82340;
$box-shadow: 0 2px 16px rgba(0, 0, 0, 0.16);
