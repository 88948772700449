.icon-input {
  background-position: 10px center;
  background-size: auto 22px;
  background-repeat: no-repeat;
  padding-left: 40px !important;

  &.time {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M960.622 586.75a.601.601 0 0 0-.185-.44.599.599 0 0 0-.438-.185.599.599 0 0 0-.439.186.601.601 0 0 0-.185.439V593c0 .091.016.176.049.254a.485.485 0 0 0 .156.205c.006.006.01.016.01.03l3.098 3.085c.117.13.263.196.438.196a.565.565 0 0 0 .439-.196.597.597 0 0 0 .175-.44.597.597 0 0 0-.175-.439l-2.943-2.949zm-4.03 14.326a8.83 8.83 0 0 1-2.774-1.885 9.035 9.035 0 0 1-1.875-2.783 8.44 8.44 0 0 1-.693-3.408c0-1.21.231-2.347.693-3.408a8.914 8.914 0 0 1 1.875-2.774 8.914 8.914 0 0 1 2.774-1.875 8.44 8.44 0 0 1 3.408-.693 8.44 8.44 0 0 1 3.408.693 8.914 8.914 0 0 1 2.774 1.875 8.914 8.914 0 0 1 1.875 2.774 8.44 8.44 0 0 1 .693 3.408 8.44 8.44 0 0 1-.693 3.408 9.035 9.035 0 0 1-1.875 2.783 8.83 8.83 0 0 1-2.774 1.885 8.44 8.44 0 0 1-3.408.694 8.44 8.44 0 0 1-3.408-.694zm-.488-17.295a10.035 10.035 0 0 0-3.184 2.139 10.035 10.035 0 0 0-2.139 3.184A9.801 9.801 0 0 0 950 593c0 1.38.26 2.679.781 3.896a10.035 10.035 0 0 0 2.139 3.184 10.035 10.035 0 0 0 3.184 2.139A9.801 9.801 0 0 0 960 603c1.38 0 2.679-.26 3.896-.781a10.035 10.035 0 0 0 3.184-2.139 10.035 10.035 0 0 0 2.139-3.184A9.801 9.801 0 0 0 970 593c0-1.38-.26-2.679-.781-3.896a10.035 10.035 0 0 0-2.139-3.184 10.035 10.035 0 0 0-3.184-2.139A9.801 9.801 0 0 0 960 583c-1.38 0-2.679.26-3.896.781z' id='a'/%3E%3C/defs%3E%3Cg transform='translate(-950 -583)'%3E%3Cuse xlink:href='%23a' fill='%2300aada'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.usermenu form input,
input[type="password"],
input[type="text"],
textarea,
input[type="email"],
input[type="phone"],
.mat-dialog-container .file-url,
.mat-dialog-container select {
  outline: none;
  background-color: transparent;
  border: thin solid $gray;
  color: #949698;
  font-size: 0.925rem;
  font-weight: 400;
  padding: 0.6875rem 1rem;
  width: 100%;
  transition: border 150ms ease-in;
}
.usermenu form input:focus,
.login-screen input:focus[type="text"],
.login-screen input:focus[type="password"],
.mat-dialog-container input:focus[type="text"],
.mat-dialog-container textarea:focus,
.mat-dialog-container input[type="email"],
.mat-dialog-container input[type="phone"],
.mat-dialog-container select:focus {
  outline: none;
}

.vozni-park .timerange input,
.login-screen input[type="submit"],
.usermenu form input,
input[type="password"],
input[type="text"],
input[type="email"],
input[type="phone"],
input[type="number"],
textarea,
.mat-dialog-container select {
  border-radius: 8px;
}

input:focus,
button:focus,
select:focus {
  outline: none;
}

.input-placeholder::-webkit-input-placeholder,
.usermenu form input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="phone"]::-webkit-input-placeholder,
.mat-dialog-container select::-webkit-input-placeholder {
  color: $gray;
  transition: color 150ms ease-in;
}

.input-placeholder:-moz-placeholder,
.usermenu form input:-moz-placeholder,
.login-screen input:-moz-placeholder[type="text"],
.login-screen input:-moz-placeholder[type="password"],
.mat-dialog-container input:-moz-placeholder[type="text"],
.mat-dialog-container textarea,
.mat-dialog-container input:-moz-placeholder[type="email"],
.mat-dialog-container input:-moz-placeholder[type="phone"],
.mat-dialog-container select:-moz-placeholder {
  color: $gray;
  opacity: 1;
}

.input-placeholder::-moz-placeholder,
.usermenu form input::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="text"]::-moz-placeholder,
textarea::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="phone"]::-moz-placeholder,
.mat-dialog-container select::-moz-placeholder {
  color: $gray;
  opacity: 1;
}

.input-placeholder:-ms-input-placeholder,
.usermenu form input:-ms-input-placeholder,
.login-screen input:-ms-input-placeholder[type="text"],
.login-screen
  input:-ms-input-placeholder[type="textarea"]
  .login-screen
  input:-ms-input-placeholder[type="password"],
.mat-dialog-container input:-ms-input-placeholder[type="text"],
.mat-dialog-container input:-ms-input-placeholder[type="textarea"],
.mat-dialog-container select:-ms-input-placeholder {
  color: $gray;
}

.input-placeholder::-ms-input-placeholder,
.usermenu form input::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="text"]::-ms-input-placeholder,
input:-ms-input-placeholder[type="textarea"],
input[type="email"]::-ms-input-placeholder,
input[type="phone"]::-ms-input-placeholder,
.mat-dialog-container select::-ms-input-placeholder {
  color: $gray;
}

.input-placeholder::placeholder,
.usermenu form input::placeholder,
input[type="password"]::placeholder,
input[type="text"]::placeholder,
textarea::placeholder,
input[type="email"]::placeholder,
input[type="phone"]::placeholder,
.mat-dialog-container select::placeholder {
  color: $gray;
  transition: color 150ms ease-in;
}

.input-placeholder:focus,
.usermenu form input:focus,
.login-screen input:focus[type="text"],
.login-screen textarea:focus,
.login-screen input:focus[type="password"],
.mat-dialog-container input:focus[type="text"],
.mat-dialog-container textarea:focus,
.mat-dialog-container select:focus {
  outline: none;
  border-color: $primary;
}
.input-placeholder:focus::-webkit-input-placeholder,
.usermenu form input:focus::-webkit-input-placeholder,
input[type="password"]:focus::-webkit-input-placeholder,
input[type="text"]:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder,
input[type="email"]:focus::-webkit-input-placeholder,
input[type="phone"]:focus::-webkit-input-placeholder,
.mat-dialog-container select:focus::-webkit-input-placeholder {
  color: transparent;
}
.input-placeholder:focus:-moz-placeholder,
.usermenu form input:focus:-moz-placeholder,
.login-screen input:focus:-moz-placeholder[type="text"],
.login-screen input:focus:-moz-placeholder[type="password"],
.mat-dialog-container input:focus:-moz-placeholder[type="text"],
.mat-dialog-container input:focus:-moz-placeholder[type="textarea"],
.mat-dialog-container select:focus:-moz-placeholder {
  color: transparent;
  opacity: 0;
}
.input-placeholder:focus::-moz-placeholder,
.usermenu form input:focus::-moz-placeholder,
input[type="password"]:focus::-moz-placeholder,
input[type="text"]:focus::-moz-placeholder,
textarea:focus::-moz-placeholder,
input[type="phone"]:focus::-moz-placeholder,
input[type="email"]:focus::-moz-placeholder,
.mat-dialog-container select:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}
.input-placeholder:focus:-ms-input-placeholder,
.usermenu form input:focus:-ms-input-placeholder,
.login-screen input:focus:-ms-input-placeholder[type="text"],
.login-screen input:focus:-ms-input-placeholder[type="password"],
.mat-dialog-container input:focus:-ms-input-placeholder[type="text"],
.mat-dialog-container input:focus:-ms-input-placeholder[type="textarea"],
.mat-dialog-container select:focus:-ms-input-placeholder {
  color: transparent;
}
.input-placeholder:focus::-ms-input-placeholder,
.usermenu form input:focus::-ms-input-placeholder,
input[type="password"]:focus::-ms-input-placeholder,
input[type="text"]:focus::-ms-input-placeholder,
textarea:focus::-ms-input-placeholder,
input[type="email"]:focus::-ms-input-placeholder,
input[type="phone"]:focus::-ms-input-placeholder,
.mat-dialog-container select:focus::-ms-input-placeholder {
  color: transparent;
}
.input-placeholder:focus::placeholder,
.usermenu form input:focus::placeholder,
input[type="password"]:focus::placeholder,
input[type="text"]:focus::placeholder,
textarea:focus::-ms-input-placeholder,
input[type="email"]:focus::placeholder,
input[type="phone"]:focus::placeholder,
.mat-dialog-container select:focus::placeholder {
  color: transparent;
}

input {
  &.ng-dirty.ng-invalid {
    border-color: #cc3f44 !important;
  }
}

.search-form {
  position: relative;

  form {
    border: thin solid $gray;
    border-radius: 20px;
    position: relative;
    width: 100%;
    height: 40px;
  }
  input {
    border: none;
    background-color: transparent;
    font-weight: 400;
    font-style: italic;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    width: 100%;
    color: $light-gray;
    font-size: 0.875rem;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 40px;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0 24px;
    width: auto;
    border: none;
    height: 32px;
    border-radius: 16px;
    background-color: $primary;
    color: #f3f3f3;
    font-size: 0.875rem;
    font-weight: 700;
    position: absolute;
    top: 3px;
    right: 4px;
  }
}

.mat-form-field-infix {
  background-color: white !important;
}
.mat-form-field-flex {
  background-color: white !important;
  border-radius: 8px;
  border: thin solid $gray;
  color: #949698;
  font-size: 15px;
}
.mat-form-field {
  display: block;
}
.mat-form-field-underline {
  display: none;
}

form.submitted {
  input.ng-invalid {
    border-color: $red !important;
  }
}

.ngx-file-drop__drop-zone {
  height: auto;
  border: none;
  border-radius: 0;
  .ngx-file-drop__content {
    height: auto;
  }
}
.ngx-file-drop {
  &__drop-zone {
    border: none !important;
    border-radius: 0 !important;
    height: auto !important;
    margin: 0 !important;
  }
  &__content {
    height: auto !important;
  }
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.submitted {
  .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
    color: #cc3f44;
  }
  .ng-invalid .mat-form-field-flex {
    border-color: #cc3f44;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #cc3f44;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 8px !important;
}

input[type="text"].small {
  height: 36px;
  padding: 0 1rem;
}
